import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Input,
  Heading
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Regions from '@components/Regions';
import { TfiExport } from 'react-icons/tfi';
import { CSVLink } from 'react-csv';
import TypePv from "@components/TypePv";
import { startOfDay, endOfDay } from '../../utils/date';
import DataGrid from '@components/DataGrid';
import { useTicketsWon } from '../../hooks/useRapports';
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';
import Paid from '../../components/Paid';
import Games from '../../components/Games';
import Pagination from '@components/Pagination';
import { ticketsWon } from '../../store/server/rapports/ticketsWon';
import { CSVDownload } from 'react-csv';
import DownloadCSVButton from '../../components/ExportButton';
import { transformTicketWon } from '@utils/transformData';
import moment from 'moment';
import axios from 'axios';
import Filter from '../../components/Filter';

function getMonthNumber(month) {
  const months = {
    janvier: 1,
    fevrier: 2,
    mars: 3,
    april: 4,
    may: 5,
    juin: 6,
    juillet: 7,
    aout: 8,
    septembre: 9,
    octobre: 10,
    novembre: 11,
    decembre: 12,
  };

  // Normalize input by trimming whitespace and converting to lowercase
  const normalizedMonth = month.trim().toLowerCase();

  // Return the month number or null if the month is invalid
  return months[normalizedMonth] || null;
}


function filterSales(data, game, sales) {
  const result = [];

  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (game === "All" || (game !== "All" && data[i].tickettype === game))
        result.push(data[i]);
    }
  }
  if (sales === "Meilleurs Vendeurs")
    result.sort((a, b) => b.enjeux - a.enjeux);
  return result;
}

const Proces_Verbal = () => {
  const [region, setRegion] = useState();
  const [rapport, setRapport] = useState("PV Mensuel");
  const [endDate, setEndDate] = useState(moment().format("DD-MM-YYYY").toString());
  const [startDate, setStartDate] = useState(moment().format("DD-MM-YYYY").toString());
  const [isLoading, setIsLoading] = useState(true);
  const [month, setMonth] = useState('janvier');
  const [year, setYear] = useState(moment().year());
  const token = localStorage.getItem('bo-token');
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]) ;

  const transformDateData = (data) => {
    console.log({data});
    const transformed = Object.keys(data).map((date) => {
      const day = new Date(date).toLocaleString('en-US', { weekday: 'long' }); // Change locale and options as needed
      return {
        day: t(day),
        date: date,
        totalStake: data[date],
      };
    });
    return transformed;
  };

  const parsePvMensuel = (data) => {
    console.log(data)
    const transformed = data.data.map((row) => {
      return {
        date: row.date,
        enjeux: row.enjeux,
        gain: row.gains,
      }
    });
    return transformed;
  }
  

  useEffect(() => {
		setIsLoading(false);
    const newEndDate = moment(endDate, "DD-MM-YYYY").set({ hour: 23, minute: 59, second: 0 }).format("DD-MM-YYYY");

		const getRapports = async () => {
      try {
        if (rapport === "PV Mensuel Détailé") {
					const response = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/agents/monthly-spm`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
						params: {
							month: getMonthNumber(month),
							year: year,
						}
					});
          setData(parsePvMensuel(response.data));
        }
        else if (rapport === "Recapulatif des ventes") {
          const response = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/custom-ticket/daily-bets`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: startDate,
              endDate: newEndDate,
              regionName: region,
            }
          });
          setData(transformDateData(response.data));
        }
        else if (rapport === "PV Mensuel") {
          const response = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/custom-ticket/stats`, {
						headers: {
							Authorization: `Bearer ${token}`,
						},
						params: {
							startDate: startDate,
							endDate: newEndDate,
							regionName: region,
						}
					});
          setData(response.data);
        }
			} catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
				setIsLoading(true);
			}
		}
		getRapports();
	}, [startDate, endDate, region, rapport, month, year]);


  const { t } = useTranslation();

  const [columns, setColumns] = useState([
    {
      key: 'type',
      label: 'Type'
    },
    {
      key: 'enjeux',
      label: t('Stakes')
    },
    {
      key: 'ticketsVendus',
      label: t('TicketsSold')
    },
    {
      key: 'gain',
      label: t('Profit')
    }
  ]);

  useEffect(() => {
    if (rapport === "Recapulatif des ventes") {
      setColumns([
        {
          key: 'day',
          label: t('Day')
        },
        {
          key: 'date',
          label: t('Date')
        },
        {
          key: 'totalStake',
          label: t('Sales')
        }
      ]);
    }
    else if (rapport === "PV Mensuel") {
      setColumns([
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'enjeux',
          label: t('Stakes')
        },
        {
          key: 'ticketsVendus',
          label: t('TicketsSold')
        },
        {
          key: 'gain',
          label: t('Profit')
        }
      ])
    }
    else {
      setColumns([
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'enjeux',
          label: t('Stakes')
        },
        {
          key: 'gain',
          label: t('Profit')
        }
      ]);
    }
  }, [rapport])

  const [dataPerPage, setDataPerPage] = useState(10);
  const indexOfLastRecord = page * dataPerPage;
  const indexOfFirstRecord = indexOfLastRecord - dataPerPage;

  const currentData = data ? data.slice(indexOfFirstRecord, indexOfLastRecord) : null;
  const nPages = Math.ceil(data.length / dataPerPage);
  return (
    <Box p={3} w="100%" overflow="auto" display={"flex"} flexFlow={"column"} gap={"40px"} >
      <Box p={3} w={"100%"} overflow="auto">
        <Heading textAlign={"center"}>Proces Verbaux</Heading>
        <Flex my={5} alignItems={"end"} justifyContent={"space-between"}>
          <Box w="100%" display={"flex"} flexFlow={"column"} gap={"40px"}>
            <FormControl>
              <h6>Rapports</h6>
              <TypePv value={rapport} setValue={setRapport} data={['PV Mensuel', "PV Mensuel Détailé" ,'Recapulatif des ventes']} />
            </FormControl>
          </Box>
          {data && (
            <Button size="sm" leftIcon={<TfiExport />} colorScheme="pink" variant="outline">
              <CSVLink
                filename="rapportsPv.csv"
                headers={columns}
                data={data}>
                {t('export')}
              </CSVLink>
            </Button>
          )}
        </Flex>
          {rapport !== "PV Mensuel Détailé"  && (
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>  
              <FormControl>
                <h6>{t('from')}</h6>

                <Input
                  width='full'
                  type="date"
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(moment(e.target.value).format("DD-MM-YYYY"))}
                />
              </FormControl>
              <FormControl>
                <h6>{t('to')}</h6>

                <Input
                  width='full'
                  type="date"
                  placeholder="End Date"
                  onChange={(e) => setEndDate(moment(e.target.value).format("DD-MM-YYYY"))}
                />
              </FormControl>
              <FormControl>
                <h6>{t('regions')}</h6>
                <Regions value={region} setValue={setRegion} />
              </FormControl>
            </Grid>
          )}
          {rapport === "PV Mensuel Détailé" && (
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>  
              <FormControl>
                <h6>{t('month')}</h6>
                <Filter value={month} setValue={setMonth} data={["janvier", 'fevrier', 'mars', 'avril', 'may', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre']} />
              </FormControl>
              <FormControl>
                <h6>{t('year')}</h6>
                <Filter value={year} setValue={setYear} data={Array.from({ length: new Date().getFullYear() - 2019 + 1 }, (_, index) => 2019 + index)} />
              </FormControl>
            </Grid>
          )}
      </Box>
      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          pageCount={nPages}
          onPrevious={() => setPage(page > 0 ? page - 1 : page)}
          onNext={() => setPage(page < nPages ? page + 1 : page)}
          onPageChange={(page) => setPage(page)}
        />
      </Flex>
      <DataGrid
        columns={columns}
        rows={currentData}
      />
    </Box>
  );
}

const TicketsWon = () => {
  const [agent, setAgent] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [paid, setPaid] = useState("All");
  const [region, setRegion] = useState();
  const [page, setPage] = useState(1);
  const [origin, setOrigin] = useState("Retail");
  const {t} = useTranslation();
  const { data, isLoading } = useTicketsWon({
    agent,
    endDate: endDate,
    startDate: startDate,
    region,
    page,
    paid,
    origin
  });

  useEffect(() => {

  }, [paid, origin]);

  const columns = [
    {
      key: 'id',
      label: 'Ticket'
    },
    {
      key: 'uid',
      label: t('Seller')
    },
    {
      key: 'status',
      label: 'status'
    },
    {
      key: 'date',
      label: 'date'
    },
    {
      key: 'profit',
      label: t('Profit')
    }
  ];

  const baseUrl = `${process.env.API_PROTOCOL}://${process.env.API_URL}`;

  return (
    <Box p={3} w="100%" overflow="auto" display={"flex"} flexFlow={"column"} gap={"40px"}>
      <Box p={3} w={"100%"} overflow="auto">
        <Heading textAlign={"center"}>{t('WinningTickets')}</Heading>
        <Flex my={5} alignItems={"end"} justifyContent={"space-between"}>
          <Input
            name="search"
            type="text"
            placeholder={t('searchBySeller')}
            maxW="sm"
            onChange={(e) => {
              setPage(1);
              setAgent(e.target.value);
            }}
          />
        <DownloadCSVButton
          options={{
            path: '/tickets',
            fetchParams: ({ pagination }) => {
              const searchParams = {
                populate: 'paidBy,issuer',
                'pagination[page]': pagination.page,
                'pagination[pageSize]': pagination.pageSize,
                'filters[createdAt][$gte]': startDate,
                'filters[createdAt][$lte]': endDate,
                'filters[issuer][username][$contains]': agent,
                'filters[win][$gte]': 1,
                'filters[selling_point][zone][city][region][name][$eq]': region,
                'sort[createdAt]': 'DESC'
              };
              if (paid === "Paid" || paid === "Payé")
                searchParams['filters[paid]'] = true;
              else if (paid === "Unpaid" || paid === "Non payé")
                searchParams['filters[paid]'] = false;
              if (origin === "USSD") {
                searchParams['filters[issuer][username][$eq]'] = "USSD";
              } else if (origin === "Retail") {
                searchParams['filters[issuer][username][$ne]'] = "USSD";
              }
              return `${convertObjectToSearchParams(searchParams)}`;
            },
            transformResponse: (allDataFinal) => transformTicketWon(allDataFinal, paid, origin, t)
          }}
          src={baseUrl}
          execDownload={({ data, ref }) => {
            return <CSVDownload data={data} ref={ref} target="self" />;
          }}
        />
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <FormControl>
            <h6>{t('from')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="Start Date"
              onChange={(e) => setStartDate(new Date(startOfDay(e.target.value)).toISOString())}
            />
          </FormControl>
          <FormControl>
            <h6>{t('to')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="End Date"
              onChange={(e) => setEndDate(new Date(endOfDay(e.target.value)).toISOString())}
            />
          </FormControl>

          <FormControl>
            <h6>{t('regions')}</h6>
            <Regions value={region} setValue={setRegion} />
          </FormControl>
          <FormControl>
            <h6>{t('Status')}</h6>
            <Paid value={paid} setValue={setPaid} />
          </FormControl>
          <FormControl>
            <h6>{t('Type')}</h6>
            <Filter value={origin} setValue={setOrigin} data={["Retail", "USSD"]} />
          </FormControl>
        </Grid>
      </Box>
      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          pageCount={data?.data?.meta.pagination.pageCount}
          onPrevious={() => setPage(page > 0 ? page - 1 : page)}
          onNext={() => setPage(page < data?.data?.meta.pagination.pageCount ? page + 1 : page)}
          onPageChange={(page) => setPage(page)}
        />
      </Flex>
      {data && data.data && (
        <DataGrid
          isLoading={isLoading}
          columns={columns}
          rows={ticketsWon(data.data.data, paid, t)}
        />
      )}
    </Box>
  )
}

const transformData = (data) => {
  const transformed = [];

  data.data.forEach((item) => {
      transformed.push({
        date: item.date,
        code_agent: item.code_agent,
        region: item.region,
        vente_spm_100: item.vente_spm_100.tickets_amount,
        vente_spm_200: item.vente_spm_200.tickets_amount,
        vente_spm_300: item.vente_spm_300.tickets_amount,
        vente_spm_400: item.vente_spm_400.tickets_amount,
        vente_spm_500: item.vente_spm_500.tickets_amount,
      });
  });

  return transformed;
};

const ventesData = (data) => {
  const transformed = [];

  data.forEach((item) => {
    Object.keys(item.types).forEach((typeKey) => {
      transformed.push({
        agent: item.agent,
        tickettype: item.types[typeKey].tickettype,
        ticketvendu: item.types[typeKey].ticketvendu,
        enjeux: item.types[typeKey].enjeux,
      });
    });
  });

  return transformed;
};

const Sales = () => {
  const {t} = useTranslation();
  const [region, setRegion] = useState();
  const [sales, setSales] = useState("Ventes Détailées");
  const [game, setGame] = useState("All");
  const [endDate, setEndDate] = useState(moment().format("DD-MM-YYYY").toString());
  const [startDate, setStartDate] = useState(moment().format("DD-MM-YYYY").toString());
  const [isLoading, setIsLoading] = useState(true);
  const [data, setDate] = useState([]);
  const [page, setPage] = useState(1);
  const token = localStorage.getItem('bo-token');

  useEffect(() => {
    const getSales = async () => {
      setIsLoading(false);
      try {
        if (sales === "Ventes Détailées") {
          const newStartDate = moment(startDate, "DD-MM-YYYY").set({ hour: 23, minute: 59, second: 0 }).format("YYYY-MM-DD");
          const newEndDate = moment(endDate, "DD-MM-YYYY").set({ hour: 23, minute: 59, second: 0 }).format("YYYY-MM-DD");
          const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/agents/sales-pv-v2`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: newStartDate,
              endDate:  newEndDate,
            }
          });
          setDate(transformData(res.data));
        }
        else if (sales === "Meilleurs Vendeurs") {
          const newStartDate = moment(startDate, "DD-MM-YYYY").set({ hour: 23, minute: 59, second: 0 }).format("DD-MM-YYYY");
          const newEndDate = moment(endDate, "DD-MM-YYYY").set({ hour: 23, minute: 59, second: 0 }).format("DD-MM-YYYY");
          const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/custom-ticket/agent-win-lost-tickets`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: newStartDate,
              endDate: newEndDate,
              regionName: region,
            }
          });
          setDate(res.data);
        }
        else if (sales === "Ventes") {
          const newStartDate = moment(startDate, "DD-MM-YYYY").set({ hour: 23, minute: 59, second: 0 }).format("DD-MM-YYYY").toString();
          const newEndDate = moment(endDate, "DD-MM-YYYY").set({ hour: 23, minute: 59, second: 0 }).format("DD-MM-YYYY").toString();
          const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/custom-ticket/agent-win-lost-tickets-by-type`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: newStartDate,
              endDate: newEndDate,
              regionName: region,
            }
          });
          setDate(ventesData(res.data));
        }
      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        setIsLoading(true);
      }
      setIsLoading(true);
    }
    getSales();
  }, [startDate, endDate, region, sales]);

  const [columns, setColumns] = useState([
    {
      key: 'agent',
      label: t('Agent Code')
    },
    {
      key: 'tickettype',
      label: t('Game')
    },
    {
      key: 'ticketvendu',
      label: t('TicketsSold')
    },
    {
      key: 'enjeux',
      label: t('Stakes')
    }
  ]);

  useEffect(() => {

  }, [game]);

  useEffect(() => {
    if (sales === "Meilleurs Vendeurs") {
      setColumns([
        {
          key: 'codeAgent',
          label: t('Agent Code')
        },
        {
          key: 'ticketsVendus',
          label: t('TicketsSold')
        },
        {
          key: 'enjeux',
          label: t('Stakes')
        }
      ]);
      setGame('All');
    }
    else if (sales === "Ventes") {
      setColumns([
        {
          key: 'agent',
          label: t('Agent Code')
        },
        {
          key: 'tickettype',
          label: t('Game')
        },
        {
          key: 'ticketvendu',
          label: t('TicketsSold')
        },
        {
          key: 'enjeux',
          label: t('Stakes')
        }
      ])
    }
    else {
      setColumns([
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'code_agent',
          label: t('Agent Code')
        },
        {
          key: 'region',
          label: 'Region'
        },
        {
          key: 'vente_spm_100',
          label: t('salesSpm') + ' 100'
        },
        {
          key: 'vente_spm_200',
          label: t('salesSpm') + ' 200'
        },
        {
          key: 'vente_spm_300',
          label: t('salesSpm') + ' 300'
        },
        {
          key: 'vente_spm_400',
          label: t('salesSpm') + ' 400'
        },
        {
          key: 'vente_spm_500',
          label: t('salesSpm') + ' 500'
        }
      ]);
    }
    setPage(1);
  }, [sales]);

  const [dataPerPage, setDataPerPage] = useState(10);
  const indexOfLastRecord = page * dataPerPage;
  const indexOfFirstRecord = indexOfLastRecord - dataPerPage;

  const currentData = (filterSales(data, game, sales)).slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil((filterSales(data, game, sales)).length / dataPerPage);

  return (
    <Box p={3} w="100%" overflow="auto" display={"flex"} flexFlow={"column"} gap={"40px"} >
      <Box p={3} w={"100%"} overflow="auto">
        <Heading textAlign={"center"}>{t('Sales')}</Heading>
        <Flex my={5} alignItems={"end"} justifyItems={"space-between"}>
          <FormControl>
            <h6>{t('Sales')}</h6>
            <TypePv value={sales} setValue={setSales} data={["Ventes", "Ventes Détailées" ,"Meilleurs Vendeurs"]}/>
          </FormControl>
          {data && (
            <Button size="sm" leftIcon={<TfiExport />} colorScheme="pink" variant="outline">
              <CSVLink
                filename="sales.csv"
                headers={columns}
                data={data}>
                {t('export')}
              </CSVLink>
            </Button>
          )}
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <FormControl>
            <h6>{t('from')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="Start Date"
              onChange={(e) => setStartDate(moment(e.target.value).format("DD-MM-YYYY"))}
            />
          </FormControl>
          <FormControl>
            <h6>{t('to')}</h6>

            <Input
              width='full'
              type="date"
              placeholder="End Date"
              onChange={(e) => setEndDate(moment(e.target.value).format("DD-MM-YYYY"))}
            />
          </FormControl>
          {sales === "Meilleurs Vendeurs" && (
            <FormControl>
              <h6>{t('regions')}</h6>
              <Regions value={region} setValue={setRegion} />
            </FormControl>
          )}
        </Grid>
      </Box>
      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          pageCount={nPages}
          onPrevious={() => setPage(page > 0 ? page - 1 : page)}
          onNext={() => setPage(page < nPages ? page + 1 : page)}
          onPageChange={(page) => setPage(page)}
        />
      </Flex>
      <DataGrid
        columns={columns}
        rows={currentData}
        isLoading={!isLoading}
      />
    </Box>
  );
}

const Rapports = () => {
  return (
    <Box w={"100%"} display={"flex"} flexDirection={"column"} gap={"20px"} py={"10px"}>
      <Proces_Verbal />
      <TicketsWon />
      <Sales />
    </Box>
  );
}

export default Rapports;